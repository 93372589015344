.group-order-container {
  padding: 30px 0;

  .share {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;

    img {
      width: 15px;
    }

    a {
      color: #e69220;
      font-weight: 600;
    }
  }
}

.form-flex {
  display: flex;
  align-items: center;
  gap: 20px;

  .form-group {
    align-items: center;
    cursor: pointer;
    gap: 6px;
    margin: 0;
    display: flex;
    gap: 10px;

    label {
      text-transform: capitalize;
      color: #888;
      font-size: 15px;
      margin-left: 3px;
      cursor: pointer;
      margin: 0;
    }
  }
}

.banner {
  position: relative;
  height: 280px;
  overflow: hidden;

  img {
    width: 100%;
    position: absolute;
    top: -195px;
  }

  .group-name {
    color: #e69220;
    background-color: #fff !important;
    padding: 10px 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.group-cards {
  display: grid;
  gap: 1rem;
  /* Spacing between cards */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding-top: 30px;

  img {
    width: 100%;
  }

  .card-outer {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: .3s all;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;

    .bottom-inner {
      padding: 15px 10px 15px;

      h2 {
        margin: 0;
        font-size: 16px;
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        width: 250px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        margin: 0;
        font-size: 14px;
        width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        opacity: .7
      }
    }

    .card-inner {
      position: relative;
      overflow-y: hidden;

      .btn-joined.active {
        color: #fff;
        background-color: #e69220;

        svg {
          fill: #fff;
        }
      }

      .btn-joined {
        position: absolute;
        left: 15px;
        top: 15px;
        z-index: 10;
        border: 0;
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 1px 5px;
        color: #e69220;

        svg {
          width: 12px;
          fill: #e69220;
        }
      }

      .btn-edit {
        position: absolute;
        left: 50%;
        top: -25%;
        z-index: 10;
        border: 0;
        border-radius: 5px;
        transform: translateX(-50%);
        transition: .3s all;
        padding: 4px 8px;
        cursor: pointer;
        max-width: 115px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 100%;
        text-decoration: none;
        background-color: #fff;
        color:#333;
        svg {
          width: 15px;
          opacity: .6;
        }
      }

      &:hover {
        &:after {
          content: '';
          height: 100%;
        }

        .btn-edit {
          top: 40%;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 0%;
        background-color: rgba(179, 111, 20, .4);
        transition: .3s all;
      }
    }

    .bottom-outer {
      .order-date {
        .calendar-heading {
          padding: 0px 5px 0;
          height: 30px;
          gap: 5px;

          svg {
            width: 15px;
            opacity: .6;
          }
        }

        h2 {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          background-color: #e69220;
          color: #fff;
          padding: 5px;
          display: flex;
        }

        .br-white {
          border-right: 1px solid #fff;
        }

        p {
          margin: 0;
          font-size: 12px;
          opacity: .8;
        }
      }
    }
  }
}