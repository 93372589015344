body,html{
  margin:0px;
  padding:0px;
}
body{
  background-color:rgba(235,235,235,.3);
}
body *{
  box-sizing:border-box;
  font-family:"Rubik" ,sans-serif;
}
.container{
  padding-left:15px;
  padding-right:15px;
  max-width:1230px;
  width: 100%;
  margin-left:auto;
  margin-right:auto;
}

.fancy-progress-bar{
  background: #E69221;
}
.form__error{
  display: flex;
  justify-content: center;
  padding: 10px;
  color: red;
  text-align: center;
  border: 1px solid rgba(217, 18, 2, 0.3);
  border-radius: 5px;
  background: rgba(255, 21, 0, 0.02);
  margin: 10px;
}
button:disabled,
input:disabled {
  opacity: 0.7;
  cursor: default;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
.float__input{
  width: 100%;
  position: relative;
  margin-bottom:15px;
  &:last-child{
    margin-bottom: 0px;
  }
  &>.StripeElement,
  &>input{
    cursor:pointer;
    width: 100%;
    height: 50px;
    border: 1px solid #E1E1E1;
    outline: none;
    border-radius: 5px;
    padding: 10px;
    padding-top: 15px;
    padding-right: 25px;
    &.no__span{
      padding: 10px 15px;
    }
    &.error {
      border: 1px solid #E02020;
      box-shadow: 0 0 4px #E02020;
    }
  }
  &>span{
    pointer-events: none;
    margin-bottom: 0px;
    cursor:pointer;
    color: #7A7A7A;
    position: absolute;
    left: 10px;
    font-size: 14px;
    top: 16px;
    font-weight: 400;
    display: inline-flex;
    transition: .2s ease all;
    &.active{
      top: 6px;
      font-size: 11px;
    }
  }
}

.mw__100{
  max-width: 100% !important;
}

.placeholder__image {
  height: 95px;
  width: 100%;
  background-color: #e2e5e7;
  background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
  &.form__head{
    height: 185px;
  }
}

@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  transition:.4s ease opacity;
}
.modal.fade .modal-dialog {
  opacity: 0;
}
.modal.show .modal-dialog {
  opacity: 1;
}

.hidden__content{
  width: 100%;
  margin-top:15px;
  & .card__double--info{
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    grid-column-gap:15px;
    & .float__input{
      margin-bottom: 0px;
    }
  }
  & .country__info{
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    grid-column-gap:15px;
    margin-bottom: 15px;
    & .float__field{
      margin-bottom: 0px;
    }
  }
}

.float__field{
  & .float__checkbox {
    & .checkbox__container {
      &>input{
        width: auto;
        height: 24px;
        margin:4px;
        border:0px;
        font-size:14px;
        outline:none;
        display: none;
      }
      & .elem__checkbox {
        display: inline-flex;
        justify-content: flex-start;
        background-color: #eeeeee;
        border-radius: 2px;
        margin: 4px;
        & > button {
          padding: 0;
          border: none;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          background-color: #ffd6d6;
          transition: all 0.4s;
          &:hover{
            background-color: #ffc8c8;
            box-shadow: 0 0 8px #ffd6d6;
          }
        }
        & p {
          white-space:nowrap;
          padding: 5px;
          color: #000000;
          font-size: 12px;
          line-height: 12px;
          font-weight: 400;
          margin: 0px;
        }
      }
    }
    & .float__checkbox--picker {
      &>button{
        background: transparent;
        padding: 0;
        border: none;
        cursor: pointer;
        display:inline-flex;
        align-items:center;
        justify-content:center;
        position:absolute;
        right:5px;
        top:5px;
      }
      padding: 15px 12px;
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 0 3px 26px rgba(0, 0, 0, 0.1);
      position: absolute;
      left: 0px;
      bottom: 0;
      transform: translate(0, 100%);
      z-index: 1;
      width: 100%;
      height: auto;
      max-height: 240px;
      overflow-y: auto;
      display: none;
      &.active{
        display: block;
      }
      & ul {
        padding: 0px;
        list-style-type: none;
        margin: 0px;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        & > li {
          padding: 5px;
          &.active__checkbox {
            & > button {
              background-color: #67a895;
              color: #fff;
            }
          }
          & > button {
            cursor: pointer;
            border: none;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            color: #000000;
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            text-decoration: none;
            padding: 5px;
            background-color: #eeeeee;
            border-radius: 2px;
            transition: 0.4s ease all;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
    }
    & .checkbox__container {
      cursor: pointer;
      width: 100%;
      height: auto;
      min-height: 50px;
      border: 1px solid #e1e1e1;
      outline: none;
      border-radius: 5px;
      padding: 10px;
      padding-top: 15px;
      padding-bottom: 7px;
      padding-right: 25px;
      display: flex;
      padding-left: 6px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
  width: 100%;
  position: relative;
  margin-bottom:15px;
  &:last-child{
    margin-bottom: 0px;
  }
  &>textarea{
    background: #FFFFFF;
    resize:none;
    width: 100%;
    border: 1px solid #EBEBEB;
    height: 100px;
    color:#000;
    font-size:15px;
    font-weight:400;
    outline: none;
    padding:15px;
    border-radius: 5px;
  }
  &>input{
    cursor:pointer;
    background: URL('/img/deliveryarrow.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 95% center;
    width: 100%;
    height: 50px;
    border: 1px solid #E1E1E1;
    outline: none;
    border-radius: 5px;
    padding: 10px;
    padding-top: 15px;
    padding-right: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  &>span{
    pointer-events: none;
    z-index: 1;
    margin-bottom: 0px;
    cursor:pointer;
    color: #7A7A7A;
    position: absolute;
    left: 10px;
    font-size: 14px;
    top: 16px;
    font-weight: 400;
    display: inline-flex;
    transition: .2s ease all;
    &.active{
      top: 6px;
      font-size: 11px;
    }
  }
}
.add__type{
  width: 100%;
  &>input{
    border:0px;
    outline:none;
    padding:0px;
    font-size:14px;
    width: 100%;
  }
}
.double__float{
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  grid-column-gap:10px;
  width: 100%;
  &>.float__input{
    width: 100%;
  }
}
.flex-line {
  display: flex;
  align-items: center;
}

.align-center-line {
  justify-content: center;
  margin-bottom: 20px;
}

.line-line {
  border-top: 1px solid #000;
  /* You can change the color */
  width: 50px;
  /* You can adjust the width */
  margin: 0 10px;
  /* Adds space between the line and the "OR" text */
}
.already__have--acount{
  width: 100%;
  padding:10px 15px;
  background-color:#FDF1F1;
  border-radius:6px;
  margin-bottom:20px;
  &>p{
    &>button{
      border: none;
      cursor: pointer;
      padding: 0;
      background: transparent;
      text-decoration:none;
      color:#E02020;
      font-weight: 500;
      text-decoration:none;
      margin-left: 3px;
      font-size: 14px;
    }
    display: flex;
    align-items:center;
    justify-content:flex-start;
    font-size:14px;
    color:#121212;
    font-weight: 400;
    margin:0px;
    &>span{
      display:inline-flex;
      align-items:center;
      justify-content:center;
      margin-right:8px;
      &>img{
        min-width: 24px;
      }
    }
    margin:0px;
  }
}
.new__teamOrder{
  display: flex;
  align-items:center;
  justify-content:flex-start;
  &>.profile__info{
    &>span{
      display: 	inline-flex;
      margin-bottom: 3px;
      color:#373737;
    }
    &>p{
      margin:5px 0px;
      color:#373737;
      line-height: 16px;
      font-size: 15px;
    }
  }
  &>.profile__image{
    min-width:40px;
    max-width:40px;
    min-height:40px;
    max-height:40px;
    margin-right:10px;
    overflow: hidden;
    border:1px solid #DDD;
    border-radius:250px;
    background-color:rgba(0,0,0,.6);
    display: inline-flex;
    align-items:center;
    justify-content:center;
    margin-right: 10px;
    &>img {
      max-width: 100%;
    }
    &>span{
      font-size:15px;
      font-weight:400;
      color:#fff;
    }
  }
}
.welcome__back--dropdown{
  height:75px;
  position:relative;
  &.focused__dropdown{
    &>div:first-child{
      &>span{
        &>img{
          transform:rotate(0deg);
        }
      }
    }
  }
  &>.dropdown__profile{
    position:absolute;
    right:0px;
    top:80px;
    z-index:2;
    background-color: #fff;
    width: 100%;
    min-width: 260px;
    max-width: 260px;
    border-radius: 12px;
    border:1px solid #ECECEC;
    box-shadow: 0 4px 4px rgba(0,0,0,0.5);
    &.disabled{
      display: none;
    }
    &>ul{
      padding:0px;
      list-style-type: 	none;
      margin:0px;
      &>li{
        width: 	100%;

        border-bottom:1px solid rgba(151,151,151,.1);
        &:last-child{
          border-bottom:0px;
        }
        &>button{
          background: transparent;
          cursor: pointer;
          border: none;
          display:flex;
          width: 100%;
          align-items:center;
          padding:15px;
          color:#5F5F5F;
          font-size:14px;
          line-height:25px;
          font-weight:400;
          text-decoration:none;
          transition:.4s ease all;
          &:hover{
            color:#E69221;
          }
          & span{
            display: inline-flex;
            margin-right: 10px;
            min-width: 24px;
            max-width: 24px;
            &>img{
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  &>div:first-child{
    height: 100%;
    display: flex;
    align-items:center;
    justify-content:flex-start;
    padding-left:15px;
    padding-right:80px;
    border-left:1px solid rgba(151,151,151,.28);
    border-right: 1px solid rgba(151,151,151,.28);
    text-decoration: none;
    position: relative;
    &>span{
      position: absolute;
      top: 50%;
      transform: translate(-50%,0%);
      right: 12px;
      display: inline-flex;
      align-items:center;
      justify-content:center;
      &>img{
        transform:rotate(-180deg);
        transition:.4s ease all;
      }
    }
    &>.welcome__profile{
      display: flex;
      align-items:center;
      justify-content:flex-start;
      &>.profile__info{
        &>span{
          display: 	inline-flex;
          margin-bottom: 3px;
          font-size: 12px;
          font-weight: 400;
          color:#373737;
        }
        &>p{
          margin:0px;
          color:#373737;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
        }
      }
      &>.profile__image{
        min-width:40px;
        max-width:40px;
        min-height:40px;
        max-height:40px;
        margin-right:10px;
        overflow: hidden;
        border:4px solid #DDD;
        border-radius:250px;
        background-color:rgba(0,0,0,.6);
        display: inline-flex;
        align-items:center;
        justify-content:center;
        margin-right: 10px;
        &>img {
          max-width: 100%;
        }
        &>span{
          font-size:15px;
          font-weight:400;
          color:#fff;
        }
      }
    }
  }
}
.modal__footer{
  text-align: center;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #EFEFEF;
  &>p{
    text-align: center;
    margin:0px;
    color:#818181;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
}

@media (max-width:1200px){
  .order__within--container, .order__outside--container{
    grid-template-columns:repeat(4 , 1fr);
  }
  .team__container {
    padding-bottom:120px;
    & .head__order {
      &  .filter__order {
        &>p{
          font-size:16px;
          margin-right:15px;
        }
      }
      &>h2{
        font-size:18px;
      }
    }
    & .team__order--list {
      &>.team__order {
        & .team__info{
          margin-right:40px;
        }
      }
    }
  }
  .ordered__cart {
    & .outer__ordered--cart {
      & .list__ordered {
        & .ordered__container{
          grid-column-gap:15px;
          grid-row-gap:15px;
        }
      }
    }
  }
  .order__container {
    & .order__inner {
      & .order__main {
        padding-bottom:120px;
        & .order__box {
          & .order__container{
            grid-template-columns:repeat(4 , 1fr);
          }
        }
      }
    }
  }
}
//Added New Stuff for responsiveness 
@media(max-width:1124px){ .modal__wrapper {
  &>.inner__modal {
    & .product__modal {
      & .product__double {
        grid-template-columns: 1fr;
      }
    }
  }
}}
@media (max-width:991px){
  .float__field {
    & .float__checkbox {
      & .float__checkbox--picker{
        max-height: 110px;
      }
    }
  }
  .header__welcome .outer__header .welcome__back {
    padding-left:14px;
    padding-right:14px;
    .welcome__profile .profile__info > p{
      font-size:15px;
    }
  }
  .check__other {
    &>p{
      font-size:16px;
    }
    padding:25px 0px;
  }
  .modal__wrapper {
    &>.inner__modal {
      & .product__modal {
        & .product__double{
          grid-template-columns:1fr;
        }
      }
    }
  }
  .team__container {
    & .head__order{
      flex-direction:column;
      justify-content:center;
      align-items:center;
      & .filter__order{
        justify-content:center;
        align-items:center;
        margin-top:10px;
      }
    }
    & .team__order--list {
      &>.team__order{
        flex-direction:column;
        justify-content:center;
        align-items:center;
        & .team__info{
          margin-right: 0px;
          width: 100%;
          & .team__person {
            & .person__info {
              & p{
                font-size:16px;
              }
            }
          }
        }
        & .team__total{
          min-width:0px;
          width: 100%;
          max-width:100%;
        }
      }
    }
  }
  .ordered__cart {
    & .outer__ordered--cart {
      & .list__ordered {
        flex-direction:column;
        justify-content:center;
        & .ordered__container{
          margin-right: 0px;
          margin-bottom:20px;
        }
      }
    }
  }
  footer {
    & .outer__footer {
      & .bottom__footer{
        padding-top:20px;
        padding-bottom:30px;
      }
      & .top__footer{
        padding-top:20px;
        padding-bottom:15px;
        & ul{
          flex-wrap:wrap;
          &>li{
            margin-right: 0px;
            padding:10px 15px;
          }
        }
      }
    }
  }
  .invitation__block {
    padding-top:25px;
    & .orders__info{
      flex-direction:column;
      justify-content:center;
      align-items:center;
      padding-right:15px;
      &>.orders__left{
        margin-right: 0px;
        margin-bottom:20px;
        padding-left:25px;
        padding-right: 25px;
      }
    }
    & .invitation__head {
      padding-right:95px;
      &>a{
        right: 15px;
        bottom:12px;
        & img{
          max-width:70px;
        }
      }
      & .invitation__desc{
        &>span{
          font-size:24px;
          white-space: inherit;
        }
        &>p{
          font-size:16px;
          line-height:19px;
          white-space: inherit;
        }
      }
      &>.welcome__invitation {
        &>span{
          font-size:16px;
          line-height:20px;
          white-space: inherit;
        }
        &>p{
          font-size:20px;
          white-space: inherit;
          line-height:17px;
        }
      }
    }
  }
  header {
    & .outer__header{
      & .logo__wrap{
        & a{
          & img{
            max-width:125px;
          }
        }
      }
      & .group__info {
        & p{
          font-size:15px;
          padding:5px 10px;
        }
      }
      min-height:60px;
    }
  }
}
@media (max-width:767px){
  .modal__footer{
    padding-top:15px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
  }
  .welcome__back--dropdown {
    &>a{
      &>.welcome__profile{
        &>.profile__info{
          &>span{
            font-size: 10px;
          }
          &>p{
            font-size:14px;
            max-width:80px;
          }
        }
        &>.profile__image{
          min-width: 32px;
          max-width: 32px;
          min-height: 32px;
          max-height: 32px;
          &>span{
            font-size:12px;
          }
        }
      }
      padding-right:40px;
      &>span{
        &>img{
          max-width:12px;
        }
        right:3px;
      }
    }
  }
  .modal__wrapper {
    &>.inner__modal {
      & .team__modal {
        & .modal__info {
          & .modal__form {
            &>form {
              & .modal__submit{
                margin-top:0px;
              }
            }
          }
        }
      }
    }
  }
  .header__welcome{
    & .outer__header{
      & .header__logo{
        & .logo__wrap{
          margin-right: 0px;
        }
        & .group__info{
          display: none;
        }
      }
    }
  }
  .invitation__box {
    & .welcome__invitation {
      & .welcome__date{
        &>span{
          font-size:14px;
          line-height: 20px;
        }
      }
      & p{
        font-size:14px;
        line-height: 20px;
      }
    }
  }
  .order__within--container, .order__outside--container {
    grid-template-columns: repeat(2, 1fr);
  }
  .modal__wrapper {
    &>.inner__modal {
      & .replace__modal {
        &>h6{
          font-size:22px;
          line-height:33px;
        }
      }
    }
  }
  .invitation__block {
    & .orders__info {
      & .orders__left {
        & .elem__order{
          flex-direction:column;
          justify-content:center;
          align-items:center;
          padding:10px 0px;
          & .order__image{
            margin-right: 0px;
            margin-bottom:10px;
          }
        }
      }
    }
  }
  .order__container {
    & .order__inner {
      & .order__main {
        & .order__switcher {
          & ul {
            &>li {
              &>a{
                font-size:14px;
                padding-left:12px;
                padding-right:12px;
              }
            }
          }
        }
      }
    }
  }
  .modal__wrapper {
    &>.inner__modal {
      & .product__modal {
        & .product__double {
          & .product__info {
            & .product__head {
              & .left__product--head {
                &>h2{
                  font-size:24px;
                }
              }
            }
          }
          & .product__cart {
            & .order__selection {
              & .selection__list {
                & .elem__selection{
                  flex-direction:column;
                  justify-content:center;
                  align-items:center;
                  & .selected__info{
                    margin-bottom:10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .modal__wrapper {
    &>.inner__modal {
      & .product__modal {
        & .product__double{
          & .product__cart{
            padding:20px 15px;
          }
          & .product__info{
            padding:15px 20px;
          }
        }
      }
    }
  }
  .team__container {
    padding-bottom:70px;
    & .team__order--list {
      &>.team__order {
        & .team__info {
          & .team__orders--container{
            grid-template-columns:1fr;
            grid-row-gap:20px;
            max-width:340px;
          }
        }
      }
    }
  }
  .ordered__cart {
    & .outer__ordered--cart {
      &>h2{
        font-size:17px;
        line-height:19px;
        margin-bottom:10px;
      }
      & .list__ordered {
        flex-direction:column;
        justify-content:center;
        & .ordered__container{
          grid-template-columns:repeat(2 , 1fr);
        }
      }
    }
  }
  .modal__wrapper {
    &>.inner__modal {
      & .success__modal{
        padding:40px 15px;
        & .sucess__info{
          &>h2{
            font-size:20px;
            line-height:25px;
          }
          &>p{
            font-size:14px;
            line-height:20px;
          }
        }
      }
      & .team__modal {
        & .modal__info {
          & .modal__info--main {
            padding-top:12px;
            padding-bottom:12px;
            margin-bottom:15px;
            &>h2{
              font-size:20px;
              line-height:25px;
            }
            &>span{
              font-size:13px;
            }
            &>p{
              font-size:14px;
              line-height:19px;
            }
          }
        }
      }
    }
  }
  .order__container {
    & .order__head {
      & ul {
        & li{
          &>span{
            font-size:14px;
            line-height:18px;
            padding:15px 10px;
          }
        }
      }
    }
    & .order__inner {
      & .order__main {
        padding-bottom:70px;
        & .order__box {
          & .order__container{
            grid-template-columns:repeat(2 , 1fr);
          }
        }
      }
    }
  }
  .order__pagination {
    & ul {
      &>li{
        min-width:24px;
        max-width:24px;
        min-height:24px;
        max-height:24px;
        &>a{
          font-size:13px;
          min-width:24px;
          max-width:24px;
          min-height:24px;
          max-height:24px;
        }
      }
    }
  }
  footer {
    & .outer__footer {
      & .bottom__footer{
        flex-direction:column;
        justify-content:center;
        align-items:center;
        & .bottom__center{
          margin:15px 0px;
        }
      }
    }
  }
  .invitation__block {
    & .orders__info {
      &>.orders__left{
        flex-direction:column;
        justify-content:center;
        align-items:center;
        padding:10px 15px;
        text-align:center;
        &>.elem__order{
          &>span, &>p{
            font-size:14px;
            line-height:22px;
          }
        }
        &>.spacer{
          width: 80px;
          height: 1px;
          margin:12px 0px;
        }
      }
    }
  }
  .styled-checkbox + label{
    font-size:13px;
    line-height:18px;
  }
  .form__block {
    padding-top:25px;
    padding-bottom:25px;
    & .inner__form {
      & .form__wrapper {
        &>form {
          &>.delivery__details {
            &>p{
              font-size:12px;
            }
            &>span{
              font-size:14px;
            }
          }
          & .pay__info {
            & .pay__inner {
              &>span{
                font-size:10px;
              }
              &>p{
                font-size:12px;
              }
            }
          }
        }
      }
      & .form__head {
        & img{
          height: 120px;
        }
      }
      & .form__wrapper {
        &> form {
          &>.head__form {
            &> h2{
              font-size:20px;
              line-height:25px;
            }
            &>p{
              font-size:14px;
              line-height:19px;
              & br{
                display: none;
              }
            }
          }
        }
      }
      & .form__head--info {
        & .head__info{
          &>h6{
            font-size:17px;
            line-height:20px;
          }
          &>p{
            font-size:14px;
            line-height:20px;
          }
        }
      }
      & .step__form {
        & .elem__step {
          & .step__info {
            & p{
              font-size:12px;
            }
          }
        }
      }
    }
  }
  .modal__wrapper {
    &>.inner__modal {
      & .team__modal {
        & .modal__info {
          & .person__status{
            grid-template-columns:1fr;
            max-width: 340px;
            margin-left: auto;
            margin-right: auto;
            grid-row-gap:25px;
          }
        }
      }
    }
  }
}
@media (max-width:480px){
  .modal__wrapper {
    &>.inner__modal {
      & .team__modal {
        & .modal__info {
          & .person__status{
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 5px;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
  .already__have--acount{
    &>p{
      flex-direction:column;
      justify-content:center;
      align-items:center;
      &>span{
        margin-right: 0px;
        margin-bottom: 5px;
      }
      &>button{
        margin-left: 0px;
        margin-top: 5px;
      }
    }
  }
  .modal__wrapper > .inner__modal .team__modal .head__modal {
    .restaurant__name{
      padding:10px;
      max-width:calc(100%);
      & > h6{
        font-size: 18px;
        line-height:20px;
      }
      & > p{
        font-size:12px;
      }
    }
    .logo__float{
      top:15px;
      right: 15px;
      height: 55px;
      width: 55px;
      &>img{
        max-width:55px;
      }
    }
  }
  .header__welcome .outer__header .header__logo .logo__wrap a img{
    max-width: 90px;
  }
  .order__within--container, .order__outside--container {
    grid-template-columns: repeat(1, 1fr);
  }
  .modal__wrapper {
    &>.inner__modal {
      & .replace__modal {
        padding:40px 15px;
        & .replace__buttons{
          display: flex;
          flex-direction:column;
          justify-content:center;
          align-items:center;
          width: 100%;
          max-width:100%;
          &>a{
            width: 100%;
            max-width: 100%;
            margin-bottom:10px;
            margin-right: 0px!important;
            &:last-child{
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .order__container {
    & .order__inner {
      & .order__main {
        & .order__switcher {
          & ul {
            &>li {
              &>a{
                font-size:11px;
                padding-left:7px;
                padding-right:7px;
              }
            }
          }
        }
      }
    }
  }
  .hidden__content{
    & .card__double--info{
      grid-template-columns:repeat(2 , 1fr);
      grid-row-gap:15px;
      max-width:155px;
      padding-right: 5px;
      grid-column-gap:10px;
      & .float__input {
        &:nth-child(1){
          width: 70px;
        }
        &:nth-child(2){
          width: 70px;
        }
        &>span{
          font-size:12px!important;
        }
      }
    }
    & .country__info{
      grid-template-columns:1fr;
      grid-row-gap:15px;
    }
  }
  .modal__wrapper {
    &>.inner__modal {
      & .product__modal {
        & .product__double {
          & .product__info {
            & .product__desc {
              &>p{
                font-size:14px;
                line-height: 20px;
              }
            }
            & .product__head {
              & .left__product--head {
                &>h2{
                  font-size:21px;
                }
              }
            }
          }
        }
      }
    }
  }
  .ordered__cart {
    & .outer__ordered--cart {
      & .list__ordered {
        flex-direction:column;
        justify-content:center;
        & .ordered__button{
          &>h6{
            font-size:24px;
            margin-bottom:15px;
          }
          &>span{
            font-size:15px;
          }
        }
        & .ordered__container{
          grid-template-columns:1fr;
        }
      }
    }
  }
  .modal__wrapper{
    &>.inner__modal {
      & .team__modal {
        & .modal__info {
          & .modal__form{
            padding-left:15px;
            padding-bottom:25px;
            padding-right:15px;
          }
        }
      }
    }
  }
  .order__container {
    & .order__head {
      & ul {
        & li{
          &>span{
            font-size:11px;
            padding:12px 8px;
          }
        }
      }
    }
    & .order__inner {
      & .order__main {
        padding-bottom:35px;
        & .order__box{
          & .order__container{
            margin-bottom:25px;
          }
        }

        & .order__box {
          & .order__container{
            grid-template-columns:1fr;
          }
        }
      }
    }
  }
  .order__pagination {
    &>a{
      font-size:12px;
    }
    & ul {
      margin-left:11px;
      margin-right:11px;
      &>li{
        min-width:20px;
        max-width:20px;
        min-height:20px;
        max-height:20px;
        &>a{
          font-size:11px;
          min-width:20px;
          max-width:20px;
          min-height:20px;
          max-height:20px;
        }
      }
    }
  }
  .invitation__block {
    padding-top:25px;
    & .invitation__head {
      padding-right:95px;
      &>a{
        right: 15px;
        bottom:12px;
        & img{
          max-width:70px;
        }
      }
      & .invitation__desc{
        &>span{
          font-size:21px;
          white-space: inherit;
        }
        &>p{
          font-size:15px;
          line-height:18px;
          white-space: inherit;
        }
      }
      &>.welcome__invitation {
        &>span{
          font-size:15px;
          line-height:18px;
          white-space: inherit;
        }
        &>p{
          font-size:18px;
          white-space: inherit;
          line-height:17px;
        }
      }
    }
  }
  .team__container{
    padding-bottom:35px;
  }
  .form__check{
    margin-bottom:15px;
  }
  .form__block {
    & .inner__form {
      & .step__form{
        margin-bottom:20px;
        padding-left:15px;
        padding-right:15px;
        & .elem__step {
          & .step__info {
            & p{
              font-size:9px;
            }
          }
        }
      }
      & .form__wrapper{
        & .congrats__link {
          &>input{
            font-size:14px;
            line-height:23px;
          }
        }
        & .congrats__text {
          margin-bottom:30px;
          &>h2{
            font-size:20px;
            line-height:30px;
          }
          &>p{
            font-size:15px;
            line-height:22px;
          }
        }
        & form {
          & .require__form {
            &>.elem__require {
              & span{
                font-size:13px;
                line-height:20px;
              }
              & p{
                font-size:14px;
                line-height:23px;
              }
            }
          }
        }
        & .time__field {
          & .date__time--double{
            grid-template-columns:1fr;
            grid-row-gap:15px;
          }
        }
        & .double__field{
          grid-template-columns:1fr;
          grid-row-gap:15px;
        }
        padding-left:15px;
        padding-right:15px;
        padding-bottom:20px;
        & > form {
          & .after__form{
            margin-top:20px;
          }
        }
      }
    }
  }
  .invitation__block .invitation__head{
    padding-bottom:100px;
    padding-right:15px;
  }
  .invitation__box{
    width: 100%;
  }
}
@media (max-width:380px){
  .invite__all--wrapper .elem__invite--all .info__invite .template__download .template__download--button > p{
    font-size:14px;
    line-height: 23px;
  }
  .invite__all--wrapper .elem__invite--all .step__invite{
    margin-right:10px;
  }
  .invite__all--wrapper .elem__invite--all .info__invite .template__download .template__info > p{
    font-size:14px;
    line-height:20px;
  }
  .invite__all--wrapper .elem__invite--all .info__invite .template__download .template__info > img{
    min-width:32px;
    max-width:32px;
  }
  .invite__all--wrapper .elem__invite--all .info__invite .template__download{
    padding-right:14px;
  }
  .invite__all--wrapper .elem__invite--all .info__invite .template__download .template__download--button > a{
    font-size:14px;
    line-height:23px;
  }
  .order__container {
    & .order__head {
      & ul {
        & li{
          margin-right:7px;
          &:last-child{
            margin-right: 0px;
          }
          &>span{
            font-size:9px;
            padding:7px 7px;
          }
        }
      }
    }
  }
}
