.loading-animation-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1060; // because bootstrap modal z-index is 1050
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;

  img {
    pointer-events: none;
    user-select: none;
  }
}
